import * as React from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

const FormRow = ({ children }) => {
    return <div className={`c-form-row`}>{children}</div>
}

FormRow.propTypes = {
    /**
     * Inner child elements
     */
    children: PropTypes.node.isRequired
}

FormRow.defaultProps = {
    children: 'Place fields here'
}

export default FormRow
